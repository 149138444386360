import { BigNumber } from "bignumber.js"
import { ethers } from "ethers"
import GENESIS_ABI from "./genesisAbi.json"
import FIRST_EDITION_ABI from "./firstEditionAbi.json"

const EDITION_NAMES: Record<number, string> = {
  0: "Genesis",
  1: "First Edition",
}

const bn = (n: string | BigNumber, base?: number | undefined) =>
  new BigNumber(n, base)

const BITMASK_MODULUS = bn(
  "0x10000000000000000000000000000000000000000000000000000000000000000",
)
const COLOR_BITMASK_MODULUS = bn("0x1000000")
const GENESIS_ENTROPY_A = bn(
  "0x900428c2467a0c0d3a050ece653c11188d27fd971bbabc35d72c1d7387e4b9e7",
)
const GENESIS_ENTROPY_B = bn(
  "0xe9895d0dd732fb1e36d48703827f74a69f0e3a4eff4cd90812967b46516f35cf",
)
const ED1_ENTROPY_A = bn(
  "0x24eb1994b22999a9428330e650231f69ba716f811bef7dde3f7a73b0c1548151",
)
const ED1_ENTROPY_B = bn(
  "0xbace2a3d7089d722f901582121989045c3584e9093a44faebf23dd37040fe689",
)
const SIDE_LENGTH = 64
const HALF_SIDE_LENGTH = bn(SIDE_LENGTH.toString())
  .dividedToIntegerBy("2")
  .toNumber()
const FIRST_EDITION_CORNER_THRESHOLD = 4 * SIDE_LENGTH - 17
const VALUE_SHIFT_DENOMINATOR = bn("0x10000")
const THEMES = ["■▬▮▰▲▶▼◀◆●◖◗◢◣◤◥", "■▬▮▰", "▲▶▼◀", "◆●◖◗", "◢◣◤◥"]
const THEME_NAMES: Record<string, string> = {
  "■▬▮▰▲▶▼◀◆●◖◗◢◣◤◥": "Universal",
  "■▬▮▰": "Blocks",
  "▲▶▼◀": "Arrows",
  "◆●◖◗": "Discs",
  "◢◣◤◥": "Corners",
}
const SVG_SCALE = 10
const SVG_BASELINE_OFFSET = 9

const add = (a: BigNumber, b: BigNumber.Value): BigNumber =>
  a.plus(b).modulo(BITMASK_MODULUS)

const mul = (a: BigNumber, b: BigNumber.Value): BigNumber =>
  a.times(b).modulo(BITMASK_MODULUS)

const getSeed = (name: string): BigNumber =>
  bn(ethers.utils.keccak256(ethers.utils.toUtf8Bytes(name)))

const getRawCharacters = (seed: BigNumber): string =>
  THEMES[seed.modulo(5).toNumber()]

const getRawColors = (edition: number, seed: BigNumber): number[] => {
  const entropyA = edition === 0 ? GENESIS_ENTROPY_A : ED1_ENTROPY_A
  const entropyB = edition === 0 ? GENESIS_ENTROPY_B : ED1_ENTROPY_B
  const result: number[] = []
  const oddSeed = add(mul(seed, 2), 1)
  let colorSeed = mul(oddSeed, entropyA).dividedToIntegerBy(2)
  const addColor = (): void => {
    result.push(colorSeed.modulo(COLOR_BITMASK_MODULUS).toNumber())
    colorSeed = colorSeed.dividedToIntegerBy(COLOR_BITMASK_MODULUS)
  }
  let i = 0
  const resultSize = getRawCharacters(seed).length + 1
  if (resultSize > 8) {
    for (; i < 8; ++i) {
      addColor()
    }
    colorSeed = mul(oddSeed, entropyB).dividedToIntegerBy(2)
  }
  for (; i < resultSize; ++i) {
    addColor()
  }
  return result
}

const getRawRune = (edition: number, seed: BigNumber): string => {
  const entropyA = edition === 0 ? GENESIS_ENTROPY_A : ED1_ENTROPY_A
  const characters = getRawCharacters(seed)
  const modulus = seed
    .modulo(seed.modulo(3).plus(1).times(characters.length))
    .plus(characters.length)
  const result: string[] = []
  const range = Array(SIDE_LENGTH).fill(undefined)
  range.forEach((_, y) => {
    const j = y < HALF_SIDE_LENGTH ? SIDE_LENGTH - y - 1 : y
    const b = 2 * j + 1
    range.forEach((_, x) => {
      const i = x < HALF_SIDE_LENGTH ? SIDE_LENGTH - x - 1 : x
      const a = 2 * i + 1
      const residue =
        edition === 1 && a + b > FIRST_EDITION_CORNER_THRESHOLD
          ? seed.modulo(characters.length).toNumber()
          : mul(mul(add(mul(seed, 2), 1), a * b * (a + b + 1)), entropyA)
              .dividedToIntegerBy(VALUE_SHIFT_DENOMINATOR)
              .modulo(modulus)
              .toNumber()
      result.push(residue < characters.length ? characters[residue] : " ")
    })
    result.push("\n")
  })
  result.pop()
  return result.join("")
}

export default class Hashrunes {
  static FIRST_EDITION_ABI = FIRST_EDITION_ABI
  static FIRST_EDITION_CONTRACT_ADDRESS =
    "0xb0e409b7b0313402A10CaA00F53BCb6858552FDA"
  static GENESIS_ABI = GENESIS_ABI
  static GENESIS_CONTRACT_ADDRESS = "0x47dD5F6335FfEcBE77E982d8a449263d1e501301"

  edition: number
  name: string
  seed?: BigNumber
  rune?: string[][]
  colors?: Record<string, string>
  characters?: string[]
  svg?: string
  linearColorHistogram?: Record<
    string,
    { linearColor: [number, number, number]; count: number }
  >

  constructor(edition: number, name: string) {
    this.edition = edition
    this.name = name
  }

  getSeed(): BigNumber {
    if (!this.seed) {
      this.seed = getSeed(this.name)
    }
    return this.seed
  }

  getCharacters(): string[] {
    if (!this.characters) {
      this.characters = Array.from(getRawCharacters(this.getSeed()))
    }
    return this.characters
  }

  getColors(): Record<string, string> {
    if (!this.colors) {
      const characters = this.getCharacters()
      const colors = getRawColors(this.edition, this.getSeed())
      const result: Record<string, string> = {}
      ;[...characters, "background"].forEach((c, i) => {
        result[c] = `#${colors[i].toString(16).padStart(6, "0")}`
      })
      this.colors = result
    }
    return this.colors
  }

  getRune(): string[][] {
    if (!this.rune) {
      this.rune = getRawRune(this.edition, this.getSeed())
        .split("\n")
        .map(row => Array.from(row))
    }
    return this.rune
  }

  getSVG(): string {
    if (!this.svg) {
      const rune = this.getRune()
      const colors = this.getColors()
      const buffer: string[] = []
      rune.forEach((row, y) =>
        row.forEach((char, x) => {
          if (char === " ") {
            return
          }
          buffer.push(
            `<text fill="${colors[char]}" x="${x * SVG_SCALE}" y="${
              y * SVG_SCALE + SVG_BASELINE_OFFSET
            }">${char}</text>`,
          )
        }),
      )
      const size = SIDE_LENGTH * SVG_SCALE
      this.svg = `<svg xmlns="http://www.w3.org/2000/svg" style="display:block;font-family: monospace;font-size: 17px" viewBox="0 0 ${size} ${size}"><rect fill="${
        colors.background
      }" x="0" y="0" width="${size}" height="${size}" />${buffer.join(
        "",
      )}</svg>`
    }
    return this.svg
  }

  getEditionName(): string {
    return EDITION_NAMES[this.edition]
  }

  getThemeName(): string {
    return THEME_NAMES[this.getCharacters().join("")]
  }

  getDensity(): number {
    const rune = this.getRune()
    let count = 0
    rune.forEach(row =>
      row.forEach(char => {
        if (char === " ") {
          ++count
        }
      }),
    )
    return 100 * (1 - count / (SIDE_LENGTH * SIDE_LENGTH))
  }

  getLinearColorHistogram(): Record<
    string,
    { linearColor: [number, number, number]; count: number }
  > {
    if (!this.linearColorHistogram) {
      const decodeGamma = (hexChannel: string): number => {
        const channel = parseInt(hexChannel, 16) / 255
        return channel <= 0.04045
          ? channel / 12.92
          : Math.pow((channel + 0.055) / 1.055, 2.4)
      }
      const linearizeColor = (color: string): [number, number, number] => [
        decodeGamma(color.substring(1, 3)),
        decodeGamma(color.substring(3, 5)),
        decodeGamma(color.substring(5)),
      ]
      const colors = this.getColors()
      const result: Record<
        string,
        { linearColor: [number, number, number]; count: number }
      > = {
        " ": { linearColor: linearizeColor(colors.background), count: 0 },
      }
      Object.keys(colors).forEach(char => {
        result[char] = { linearColor: linearizeColor(colors[char]), count: 0 }
      })
      const rune = this.getRune()
      rune.forEach(row =>
        row.forEach(char => {
          result[char].count++
        }),
      )
      this.linearColorHistogram = result
    }
    return this.linearColorHistogram
  }

  getColorMetric(
    fn: (linearColor: [number, number, number]) => number,
  ): number {
    const histogram = this.getLinearColorHistogram()
    return (
      Object.keys(histogram).reduce(
        (accum, char) =>
          accum + fn(histogram[char].linearColor) * histogram[char].count,
        0,
      ) /
      (SIDE_LENGTH * SIDE_LENGTH)
    )
  }

  getHue(): number {
    const histogram = this.getLinearColorHistogram()
    const accum = [0, 0, 0]
    Object.keys(histogram).forEach(char => {
      accum[0] += histogram[char].linearColor[0] * histogram[char].count
      accum[1] += histogram[char].linearColor[1] * histogram[char].count
      accum[2] += histogram[char].linearColor[2] * histogram[char].count
    })
    const denom = SIDE_LENGTH * SIDE_LENGTH
    const r = accum[0] / denom
    const g = accum[1] / denom
    const b = accum[2] / denom
    const max = Math.max(r, g, b)
    const delta = max - Math.min(r, g, b)
    const hue =
      60 *
      (r === max
        ? (g - b) / delta
        : g === max
        ? 2 + (b - r) / delta
        : 4 + (r - g) / delta)
    return hue < 0 ? hue + 360 : hue
  }

  getLightness(): number {
    return this.getColorMetric(([r, g, b]) => {
      const luminance = 0.2126 * r + 0.7152 * g + 0.0722 * b
      return luminance <= 216 / 24389
        ? luminance * (24389 / 27)
        : Math.pow(luminance, 1 / 3) * 116 - 16
    })
  }

  getSaturation(): number {
    return this.getColorMetric(([r, g, b]) => {
      return 100 * (1 - Math.min(r, g, b) / Math.max(r, g, b))
    })
  }
}
