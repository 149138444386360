import React from "react"
import styled from "styled-components"
import { debounce } from "debounce"
import { ethers } from "ethers"
import ActionButton from "./ActionButton"
import Hashrune from "./Hashrune"
import Hashrunes from "../lib/hashrunes"
import { BigNumber } from "bignumber.js"
import ExternalLink from "./ExternalLink"

const DEBOUNCE_DELAY = 500
const NETWORK_NAME = "homestead"
const GENESIS_PRICE_TOLERANCE_PERCENTAGE = 5
const FIRST_EDITION_PRICE_TOLERANCE_COEFFICIENT = 20
const PREVIEW_GENESIS_HASHRUNE_TOKEN_ID = 96
const PREVIEW_FIRST_EDITION_HASHRUNE_TOKEN_ID = 5

interface Props {
  name?: string
}

interface State {
  edition: number
  errorMessage?: string
  walletErrorMessage?: string
  name?: string
  nameValue: string
  genesisContract?: ethers.Contract
  genesisPrice?: BigNumber
  genesisSupply?: BigNumber
  firstEditionContract?: ethers.Contract
  firstEditionPrice?: BigNumber
  firstEditionSupply?: BigNumber
  firstEditionMaxSupply?: BigNumber
  firstEditionPriceIncrement?: BigNumber
  network?: ethers.providers.Network
  provider?: ethers.providers.Web3Provider
  mintedTokenId?: string
  transactionHash?: string
}

export default class HashruneMinter extends React.Component<Props, State> {
  state: State = {
    edition: 1,
    name: this.props.name,
    nameValue: this.props.name ?? "",
  }

  showErrorMessage(message: string) {
    this.setState({ errorMessage: message })
  }

  async componentDidMount() {
    let provider
    let network
    try {
      await (window as any).ethereum.request({
        method: "eth_requestAccounts",
      })
      provider = new ethers.providers.Web3Provider((window as any).ethereum)
      network = await provider.getNetwork()
      this.setState({
        network,
        provider,
      })
      await this.loadFirstEditionData(provider, network)
      await this.loadGenesisData(provider, network)
    } catch (e) {
      console.warn(e)
    }
    if (provider) {
      if (network?.name !== NETWORK_NAME) {
        this.setState({
          walletErrorMessage:
            "Please connect to the Ethereum network and reload this page.",
        })
      }
    } else {
      this.setState({
        walletErrorMessage: "Please enable MetaMask and reload this page.",
      })
    }
  }

  async loadGenesisData(
    provider: ethers.providers.Web3Provider,
    network: ethers.providers.Network,
  ) {
    const genesisContract = new ethers.Contract(
      Hashrunes.GENESIS_CONTRACT_ADDRESS,
      Hashrunes.GENESIS_ABI,
      provider,
    ).connect(provider.getSigner())
    let genesisPrice
    let genesisSupply
    if (network.name === NETWORK_NAME) {
      genesisPrice = new BigNumber((await genesisContract.price()).toString())
      genesisSupply = new BigNumber(
        (await genesisContract.totalSupply()).toString(),
      )
    }
    this.setState({
      genesisContract,
      genesisPrice,
      genesisSupply,
    })
  }

  async loadFirstEditionData(
    provider: ethers.providers.Web3Provider,
    network: ethers.providers.Network,
  ) {
    const firstEditionContract = new ethers.Contract(
      Hashrunes.FIRST_EDITION_CONTRACT_ADDRESS,
      Hashrunes.FIRST_EDITION_ABI,
      provider,
    ).connect(provider.getSigner())
    let firstEditionPrice
    let firstEditionPriceIncrement
    let firstEditionSupply
    let firstEditionMaxSupply
    if (network.name === NETWORK_NAME) {
      firstEditionPrice = new BigNumber(
        (await firstEditionContract.price()).toString(),
      )
      firstEditionPriceIncrement = new BigNumber(
        (await firstEditionContract.priceIncrement()).toString(),
      )
      firstEditionSupply = new BigNumber(
        (await firstEditionContract.totalSupply()).toString(),
      )
      firstEditionMaxSupply = new BigNumber(
        (await firstEditionContract.maxSupply()).toString(),
      )
    }
    this.setState({
      firstEditionContract,
      firstEditionPrice,
      firstEditionPriceIncrement,
      firstEditionSupply,
      firstEditionMaxSupply,
    })
  }

  onGenesisMint = async () => {
    const { genesisContract, provider, name } = this.state
    if (name === undefined || !genesisContract || !provider) {
      return
    }
    const genesisPrice = new BigNumber(
      (await genesisContract.price()).toString(),
    )
    const existingTokenId = await genesisContract.getTokenId(name)
    if (!existingTokenId.eq(0)) {
      this.showErrorMessage(`Genesis "${name}" already exists.`)
      return
    }
    const value = genesisPrice
      .times(100 + GENESIS_PRICE_TOLERANCE_PERCENTAGE)
      .dividedToIntegerBy(100)
      .toString()
    const balance = new BigNumber(
      (await provider.getSigner().getBalance()).toString(),
    )
    if (balance.isLessThan(value)) {
      this.showErrorMessage("Not enough ETH in your wallet.")
      return
    }
    const transaction: ethers.providers.TransactionResponse = await genesisContract.mint(
      name,
      { value },
    )
    this.setState({
      transactionHash: transaction.hash,
    })
    await transaction.wait()
    const newGenesisPrice = new BigNumber(
      (await genesisContract.price()).toString(),
    )
    const newGenesisSupply = new BigNumber(
      (await genesisContract.totalSupply()).toString(),
    )
    const tokenId = await genesisContract.getTokenId(name)
    this.setState({
      genesisPrice: newGenesisPrice,
      genesisSupply: newGenesisSupply,
      mintedTokenId: tokenId.toString(),
      transactionHash: undefined,
    })
  }

  onFirstEditionMint = async () => {
    const {
      firstEditionContract,
      firstEditionPriceIncrement,
      genesisContract,
      name,
      provider,
    } = this.state
    if (
      name === undefined ||
      !firstEditionContract ||
      !firstEditionPriceIncrement ||
      !genesisContract ||
      !provider
    ) {
      return
    }
    const firstEditionPrice = new BigNumber(
      (await firstEditionContract.price()).toString(),
    )
    const existingTokenId = await firstEditionContract.getTokenId(name)
    if (!existingTokenId.eq(0)) {
      this.showErrorMessage(`First Edition "${name}" already exists.`)
      return
    }
    let value = firstEditionPrice
      .plus(
        firstEditionPriceIncrement.times(
          FIRST_EDITION_PRICE_TOLERANCE_COEFFICIENT,
        ),
      )
      .toString()
    const existingGenesisTokenId = await genesisContract.getTokenId(name)
    if (!existingGenesisTokenId.eq(0)) {
      const owner = await genesisContract.ownerOf(
        existingGenesisTokenId.toString(),
      )
      const currentAddress = await provider.getSigner().getAddress()
      console.log(owner, currentAddress)
      if (owner === currentAddress) {
        value = "0"
      } else {
        this.showErrorMessage(
          `The Genesis version of this Hashrune has already been minted. Only the owner can mint the First Edition version.`,
        )
        return
      }
    }
    const balance = new BigNumber(
      (await provider.getSigner().getBalance()).toString(),
    )
    if (balance.isLessThan(value)) {
      this.showErrorMessage("Not enough ETH in your wallet.")
      return
    }
    const transaction: ethers.providers.TransactionResponse = await firstEditionContract.mint(
      name,
      { value },
    )
    this.setState({
      transactionHash: transaction.hash,
    })
    await transaction.wait()
    const newGenesisPrice = new BigNumber(
      (await firstEditionContract.price()).toString(),
    )
    const newGenesisSupply = new BigNumber(
      (await firstEditionContract.totalSupply()).toString(),
    )
    const tokenId = await firstEditionContract.getTokenId(name)
    this.setState({
      firstEditionPrice: newGenesisPrice,
      firstEditionSupply: newGenesisSupply,
      mintedTokenId: tokenId.toString(),
      transactionHash: undefined,
    })
  }

  onMint = () => {
    const { edition } = this.state
    if (edition === 0) {
      this.onGenesisMint()
    } else {
      this.onFirstEditionMint()
    }
  }

  preview = debounce(
    (name: string) =>
      this.setState({
        name: name ? name.trim() : undefined,
        mintedTokenId: undefined,
        transactionHash: undefined,
      }),
    DEBOUNCE_DELAY,
  )

  renderGenesisData() {
    const { genesisPrice, genesisSupply } = this.state
    return (
      <>
        <h2 className="HashruneMinter--subheading">
          Genesis Hashrunes minted:{" "}
          <strong>{genesisSupply ? genesisSupply.toString() : "?"}</strong>
        </h2>
        <h2 className="HashruneMinter--subheading">
          Current price:{" "}
          <strong>
            {genesisPrice
              ? `${genesisPrice.shiftedBy(-18).precision(4)} ETH`
              : "?"}
          </strong>
          * (see note)
        </h2>
        <p>
          Each Hashrune minted <strong>increases the price by 1%</strong>.
        </p>
      </>
    )
  }

  renderFirstEditionData() {
    const {
      firstEditionPrice,
      firstEditionPriceIncrement,
      firstEditionSupply,
      firstEditionMaxSupply,
    } = this.state
    return (
      <>
        <h2 className="HashruneMinter--subheading">
          First Edition Hashrunes minted:{" "}
          <strong>
            {firstEditionSupply ? firstEditionSupply.toString() : "?"}/
            {firstEditionMaxSupply ? firstEditionMaxSupply.toString() : "?"}
          </strong>
        </h2>
        <h2 className="HashruneMinter--subheading">
          Current price:{" "}
          <strong>
            {firstEditionPrice
              ? `${firstEditionPrice.shiftedBy(-18).precision(4)} ETH`
              : "?"}
          </strong>
          * or <strong>free</strong>**
        </h2>
        <p>
          Each Hashrune minted{" "}
          <strong>
            increases the price by{" "}
            {firstEditionPriceIncrement?.shiftedBy(-18).toString() ?? "?"} ETH
          </strong>
          .**
        </p>
      </>
    )
  }

  render() {
    const {
      edition,
      errorMessage,
      walletErrorMessage,
      name,
      nameValue,
      firstEditionPriceIncrement,
      firstEditionSupply,
      genesisSupply,
      network,
      mintedTokenId,
      transactionHash,
    } = this.state
    return (
      <DivContainer>
        <div className="HashruneMinter--search-container">
          <h1 className="HashruneMinter--heading">Claim a Hashrune</h1>
          <div className="HashruneMinter--edition-selector">
            <button
              className={`HashruneMinter--edition-option ${
                edition === 0
                  ? "HashruneMinter--edition-option-selected HashruneMinter--edition-option-selected-genesis"
                  : ""
              }`}
              onClick={() =>
                this.setState({ edition: 0, errorMessage: undefined })
              }
            >
              Genesis
            </button>
            <button
              className={`HashruneMinter--edition-option ${
                edition === 1 ? "HashruneMinter--edition-option-selected" : ""
              }`}
              onClick={() =>
                this.setState({ edition: 1, errorMessage: undefined })
              }
            >
              First Edition
            </button>
          </div>
          {walletErrorMessage ? (
            <div className="HashruneMinter--error">{walletErrorMessage}</div>
          ) : edition === 0 ? (
            this.renderGenesisData()
          ) : (
            this.renderFirstEditionData()
          )}
          <form
            onSubmit={event => {
              event.preventDefault()
              this.setState({ name: nameValue }, this.onMint)
            }}
          >
            <input
              className="HashruneMinter--search"
              disabled={!!transactionHash || network?.name !== NETWORK_NAME}
              onChange={event => {
                const value = event.target.value
                this.setState({ errorMessage: undefined, nameValue: value })
                this.preview(value)
              }}
              placeholder="Enter name"
              type="text"
              value={nameValue}
            />
          </form>
          <ActionButton
            className={`HashruneMinter--mint ${
              errorMessage || transactionHash || name === undefined
                ? "HashruneMinter--mint-disabled"
                : ""
            }`}
            onClick={() => {
              if (transactionHash) {
                return
              }
              this.onMint()
            }}
          >
            {errorMessage
              ? "ERROR"
              : mintedTokenId
              ? "Minted!"
              : transactionHash
              ? "Minting..."
              : "Mint this Hashrune"}
          </ActionButton>
          {errorMessage ? (
            <div className="HashruneMinter--error">{errorMessage}</div>
          ) : mintedTokenId ? (
            <ActionButton
              className="App--action"
              href={`https://opensea.io/assets/${
                edition === 0
                  ? Hashrunes.GENESIS_CONTRACT_ADDRESS
                  : Hashrunes.FIRST_EDITION_CONTRACT_ADDRESS
              }/${mintedTokenId}`}
            >
              View on OpenSea
            </ActionButton>
          ) : transactionHash ? (
            <ActionButton
              className="App--action"
              href={`https://etherscan.io/tx/${transactionHash}`}
            >
              View transaction on Etherscan
            </ActionButton>
          ) : null}
          <div className="HashruneMinter--price-note">
            *In case other Hashrunes are being minted at the same time, your
            transaction request in MetaMask will show an amount about{" "}
            {edition === 0
              ? `${GENESIS_PRICE_TOLERANCE_PERCENTAGE}%`
              : `${
                  firstEditionPriceIncrement
                    ?.times(FIRST_EDITION_PRICE_TOLERANCE_COEFFICIENT)
                    .shiftedBy(-18) ?? "?"
                } ETH`}{" "}
            more than the above price.{" "}
            <strong>
              Any extra ETH will be refunded back to you during the transaction.
            </strong>
          </div>
          {edition === 1 ? (
            <div className="HashruneMinter--price-note">
              **The owner of a Genesis Hashrune can mint the First Edition
              version of it for free. In this case, the price won't increase.
            </div>
          ) : null}
          <div className="HashruneMinter--price-note">
            <strong>
              By clicking, you assume all risks and liability for minting this
              NFT.
            </strong>{" "}
            You can view the smart contract{" "}
            <ExternalLink
              href={`https://etherscan.io/address/${
                edition === 0
                  ? Hashrunes.GENESIS_CONTRACT_ADDRESS
                  : Hashrunes.FIRST_EDITION_CONTRACT_ADDRESS
              }#code`}
            >
              here
            </ExternalLink>
            .
          </div>
        </div>
        <div className="HashruneMinter--preview">
          <h2 className="HashruneMinter--preview-heading">Preview</h2>
          <Hashrune
            className={name ? undefined : "HashruneMinter--preview-rune"}
            edition={edition}
            name={name ?? "Preview"}
            size={512}
            tokenId={
              name
                ? (edition === 0 ? genesisSupply : firstEditionSupply)
                    ?.plus(1)
                    .toNumber()
                : edition === 0
                ? PREVIEW_GENESIS_HASHRUNE_TOKEN_ID
                : PREVIEW_FIRST_EDITION_HASHRUNE_TOKEN_ID
            }
          />
        </div>
      </DivContainer>
    )
  }
}

const DivContainer = styled.div`
  display: flex;
  flex-direction: column;

  .HashruneMinter--heading {
    font-size: 28px;
    font-weight: 500;
    margin-bottom: 16px;
  }

  .HashruneMinter--subheading {
    font-size: 20px;
    font-weight: 500;
    margin: 0 auto 8px auto;
  }

  .HashruneMinter--error {
    display: inline-block;
    color: red;
    margin-bottom: 8px;
  }

  .HashruneMinter--search-container {
    max-width: 460px;

    .HashruneMinter--edition-selector {
      display: flex;
      margin-bottom: 16px;

      .HashruneMinter--edition-option {
        background-color: white;
        border: 1px solid #282c34;
        color: #777777;
        cursor: pointer;
        flex: 1 0;
        font-size: 16px;
        outline: none;
        padding: 8px 12px;
        text-align: center;

        &:hover {
          box-shadow: 0px 0px 0px 1px black;
        }

        &:active {
          background: #e5e5e5;
          -webkit-box-shadow: inset 0px 0px 5px #c1c1c1;
          -moz-box-shadow: inset 0px 0px 5px #c1c1c1;
          box-shadow: inset 0px 0px 5px #c1c1c1;
        }

        &:first-child {
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
        }

        &:last-child {
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
          border-left: none;
        }

        &.HashruneMinter--edition-option-selected {
          background-color: #3939ff;
          color: white;
          font-weight: 600;

          &.HashruneMinter--edition-option-selected-genesis {
            background-color: #ffd700;
            color: #282c34;
          }

          &:hover {
            box-shadow: none;
          }
        }
      }
    }

    .HashruneMinter--search {
      background-color: white;
      border-bottom: 1px solid #cccccc;
      border-left: none;
      border-right: none;
      border-top: none;
      color: #282c34;
      display: block;
      font-size: 24px;
      height: 48px;
      margin: 16px 0;
      width: 100%;

      &:focus {
        outline: none;
      }
    }
  }

  .HashruneMinter--mint {
    margin-bottom: 16px;

    &.HashruneMinter--mint-disabled {
      color: #aaaaaa;

      &:hover {
        box-shadow: none;
        cursor: not-allowed;
      }
    }
  }

  .HashruneMinter--price-note {
    font-size: 14px;
    margin-bottom: 16px;
  }

  .HashruneMinter--preview {
    .HashruneMinter--preview-heading {
      font-size: 20px;
      margin: 16px 0;
    }

    .HashruneMinter--preview-rune {
      opacity: 0.3;
    }
  }

  @media (min-width: 900px) {
    flex-direction: row;
    justify-content: center;
    width: 1200px;

    .HashruneMinter--heading {
      margin-bottom: 16px;
      margin-top: 64px;
    }

    .HashruneMinter--subheading {
    }

    .HashruneMinter--search {
      font-size: 32px;
    }

    .HashruneMinter--preview {
      margin-left: 32px;
    }
  }
`
