import React from "react"
import styled from "styled-components"

interface Props {
  children: React.ReactNode
  className?: string
  href: string
}

const ExternalLink = ({ children, className, href }: Props) => (
  <AnchorContainer
    className={className}
    href={href}
    rel="noopener noreferrer"
    target="_blank"
  >
    {children}
  </AnchorContainer>
)

const AnchorContainer = styled.a`
  cursor: pointer;
  /* text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
  &:link {
    color: #61dafb;
  }
  &:visited {
    color: #d499ff;
  } */
`

export default ExternalLink
