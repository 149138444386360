import React from "react"
import styled from "styled-components"
import Hashrunes from "../lib/hashrunes"
import ExternalLink from "./ExternalLink"

interface Props {
  className?: string
  edition: number
  isLink?: boolean
  name?: string
  size?: number
  tokenId?: number
  useApi?: boolean
}

export default class Hashrune extends React.PureComponent<Props> {
  render() {
    const {
      className,
      edition,
      isLink,
      name,
      size,
      tokenId,
      useApi,
    } = this.props
    const rune =
      tokenId && useApi ? (
        <img
          alt={name}
          className="Hashrune--rune"
          src={`https://api.hashrunes.com/v1/runes-png/${edition}/${tokenId}.png`}
          style={size ? { width: size } : undefined}
        />
      ) : (
        <div
          className="Hashrune--rune"
          dangerouslySetInnerHTML={
            name === undefined
              ? undefined
              : { __html: new Hashrunes(edition, name).getSVG() }
          }
          style={size ? { width: size } : undefined}
        />
      )
    return (
      <DivContainer className={className}>
        {tokenId && isLink ? (
          <ExternalLink
            href={`https://opensea.io/assets/${
              edition === 0
                ? Hashrunes.GENESIS_CONTRACT_ADDRESS
                : Hashrunes.FIRST_EDITION_CONTRACT_ADDRESS
            }/${tokenId}`}
          >
            {rune}
          </ExternalLink>
        ) : (
          rune
        )}
        {name !== undefined ? (
          <div className="Hashrune--title">
            #{tokenId ?? "?"}: {name}
          </div>
        ) : null}
      </DivContainer>
    )
  }
}

const DivContainer = styled.div`
  margin: 0 auto;
  position: relative;

  .Hashrune--rune {
    background: #eeeeee;
    box-shadow: #282c34 1px 1px 4px 1px;
    display: block;
    user-select: none;
    max-width: 100%;
  }

  .Hashrune--title {
    background-color: #282c3499;
    color: white;
    font-weight: 600;
    max-width: 100%;
    overflow: hidden;
    padding: 4px 8px;
    pointer-events: none;
    position: absolute;
    text-overflow: ellipsis;
    top: 0;
    white-space: nowrap;
  }
`
