import React from "react"
import styled from "styled-components"
import ExternalLink from "./ExternalLink"

interface Props {
  mintRef: HTMLDivElement | null
}

const Navbar = ({ mintRef }: Props) => (
  <DivContainer>
    <nav className="Navbar--nav-container">
      <div className="Navbar--nav">
        <a className="Navbar--nav-brand" href="/">
          Hashrunes
        </a>
        <a className="Navbar--link" href="/browse">
          GALLERY
        </a>
        {mintRef ? (
          <div
            className="Navbar--link"
            onClick={() => mintRef?.scrollIntoView({ behavior: "smooth" })}
          >
            MINT
          </div>
        ) : (
          <a className="Navbar--link" href="/#mint">
            MINT
          </a>
        )}
        <div className="Navbar--external-links">
          <ExternalLink
            className="Navbar--external-link"
            href="https://opensea.io/assets/hashrunes"
          >
            <img
              alt="OpenSea"
              className="Navbar--external-link-badge"
              src="/opensea.png"
            />
          </ExternalLink>
          <ExternalLink
            className="Navbar--external-link"
            href="https://twitter.com/joshuawu0"
          >
            <img
              alt="Twitter"
              className="Navbar--external-link-badge"
              src="/twitter.png"
            />
          </ExternalLink>
          <ExternalLink
            className="Navbar--external-link"
            href="https://discord.gg/yNzV52rdwQ"
          >
            <img
              alt="Discord"
              className="Navbar--external-link-badge"
              src="/discord.png"
            />
          </ExternalLink>
        </div>
      </div>
    </nav>
    <div className="Navbar--nav-spacer" />
  </DivContainer>
)
export default Navbar

const DivContainer = styled.div`
  .Navbar--nav-container {
    align-items: center;
    background-color: white;
    border-bottom: 1px solid #282c34;
    display: flex;
    font-size: 24px;
    font-weight: 600;
    height: 54px;
    justify-content: center;
    left: 0;
    padding: 12px;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1;

    .Navbar--nav {
      display: flex;
      height: 100%;
      margin: 0 12px;
      width: 100%;

      .Navbar--nav-brand {
        color: #282c34;
        margin-right: auto;
        text-decoration: none;
      }
    }

    .Navbar--link {
      align-items: center;
      color: #282c34;
      cursor: pointer;
      display: flex;
      font-size: 18px;
      font-weight: 600;
      justify-content: center;
      margin-left: 24px;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }

    .Navbar--external-links {
      display: none;
      justify-content: flex-end;
      margin-top: 0;

      .Navbar--external-link {
        align-items: center;
        display: flex;
        margin-left: 12px;

        &:first-child {
          margin-left: 0;
        }

        .Navbar--external-link-badge {
          height: 24px;
          object-fit: contain;
          width: 24px;
        }
      }
    }

    .Navbar--external-links {
    }
  }

  .Navbar--nav-spacer {
    height: 54px;
  }

  @media (min-width: 900px) {
    .Navbar--nav-container {
      .Navbar--nav {
        width: 1200px;

        .Navbar--external-links {
          display: flex;
          margin-left: 24px;
        }
      }
    }

    .Navbar--nav-spacer {
      height: 54px;
    }
  }
`
