import React from "react"
import styled from "styled-components"

interface Props {
  children: React.ReactNode
  className?: string
  href?: string
  onClick?: () => unknown
  style?: React.CSSProperties
}

const ActionButton = ({ children, className, href, onClick, style }: Props) => (
  <DivContainer
    className={className}
    href={href}
    onClick={onClick}
    style={style}
    target={href && !href.startsWith("/") ? "_blank" : undefined}
  >
    {children}
  </DivContainer>
)
export default ActionButton

const DivContainer = styled.a`
  border: 1px solid #282c34;
  border-radius: 4px;
  color: #282c34;
  cursor: pointer;
  display: inline-block;
  /* font-family: "Press Start 2P", cursive; */
  font-weight: 600;
  margin-right: 16px;
  padding: 8px 16px;
  text-decoration: none;

  &:hover {
    box-shadow: black 1px 1px 1px 0;
  }
`
