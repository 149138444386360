import React from "react"
import styled from "styled-components"

interface Props {
  className?: string
  tweetId: string
}

export default class Tweet extends React.Component<Props> {
  ref: HTMLDivElement | null = null

  componentDidMount() {
    if (!this.ref) {
      return
    }
    const { tweetId } = this.props
    // @ts-ignore
    twttr.ready(t =>
      t.widgets.createTweet(tweetId, this.ref, {
        // theme: "dark",
      }),
    )
  }

  render() {
    const { className } = this.props
    return (
      <DivContainer
        className={className}
        ref={ref => {
          this.ref = ref
        }}
      />
    )
  }
}

const DivContainer = styled.div``
