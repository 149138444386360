import React from "react"
import styled from "styled-components"
import { BrowserRouter, Switch, Route } from "react-router-dom"
import ActionButton from "./components/ActionButton"
import ExternalLink from "./components/ExternalLink"
import Hashrune from "./components/Hashrune"
import HashruneMinter from "./components/HashruneMinter"
import Tweet from "./components/Tweet"
import Hashrunes from "./lib/hashrunes"
import Navbar from "./components/Navbar"
import Browse from "./components/Browse"

export default class App extends React.Component {
  // currentTokenId?: number = 1
  learnMoreRef: HTMLDivElement | null = null
  mintRef: HTMLDivElement | null = null

  componentDidMount() {
    this.forceUpdate()
  }

  render() {
    return (
      <DivContainer>
        <Navbar mintRef={this.mintRef} />
        <BrowserRouter>
          <Switch>
            <Route path="/browse">
              <div className="App--container">
                <Browse />
              </div>
            </Route>
            <Route path="/">{this.renderHome()}</Route>
          </Switch>
        </BrowserRouter>
      </DivContainer>
    )
  }

  renderHome() {
    return (
      <>
        <div className="App--external-links">
          <ExternalLink
            className="App--external-link"
            href="https://opensea.io/assets/hashrunes"
          >
            <img
              alt="OpenSea"
              className="App--external-link-badge"
              src="/opensea.png"
            />
          </ExternalLink>
          <ExternalLink
            className="App--external-link"
            href="https://twitter.com/joshuawu0"
          >
            <img
              alt="Twitter"
              className="App--external-link-badge"
              src="/twitter.png"
            />
          </ExternalLink>
          <ExternalLink
            className="App--external-link"
            href="https://discord.gg/yNzV52rdwQ"
          >
            <img
              alt="Discord"
              className="App--external-link-badge"
              src="/discord.png"
            />
          </ExternalLink>
        </div>
        <section className="App--hero-container">
          <div className="App--hero">
            <Hashrune
              className="App--hero-rune"
              edition={0}
              isLink
              name="Hashrunes"
              size={512}
              tokenId={1}
              useApi
            />
            <div className="App--hero-copy">
              <h1 className="App--hero-brand">Hashrunes</h1>
              <h2 className="App--hero-title">
                On-chain art, summoned by you.
              </h2>
              <p className="App--hero-description">
                Hashrunes are the <strong>first on-chain named</strong> pieces
                of generative art.
              </p>
              <p className="App--hero-description">
                The <strong>name, design, and colors</strong> of each Hashrune
                all live <strong>completely on the blockchain</strong>.
              </p>
              <p className="App--hero-description">
                Each <strong>NFT</strong> (non-fungible token) is born from the{" "}
                <strong>unique name</strong> given by its creator.
              </p>
              <p className="App--hero-description">
                No two Hashrunes are alike. Once a name is claimed, its Hashrune{" "}
                <strong>can't</strong> be minted again.
              </p>
              <p className="App--hero-description">
                Hashrunes are <strong>very scarce</strong> due to an increasing
                minting fee.
              </p>
              <p className="App--hero-description">
                There are two editions:
                <ul className="App--hero-description-list">
                  <li className="App--hero-description-list-item">
                    <strong>Genesis Hashrunes</strong> are the original
                    Hashrunes with a pure design.
                  </li>
                  <li className="App--hero-description-list-item">
                    <strong>First Edition Hashrunes</strong> have a corner
                    design that distinguishes them from Genesis Hashrunes. They
                    have a maximum supply. Genesis owners can mint their First
                    Edition versions for free (other people can't mint them).
                  </li>
                </ul>
              </p>
              <div className="App--actions">
                <ActionButton
                  onClick={() =>
                    this.mintRef?.scrollIntoView({ behavior: "smooth" })
                  }
                >
                  Mint
                </ActionButton>
                <ActionButton
                  onClick={() =>
                    this.learnMoreRef?.scrollIntoView({ behavior: "smooth" })
                  }
                >
                  Learn more
                </ActionButton>
                <ActionButton
                  className="App--action"
                  href="https://opensea.io/assets/hashrunes"
                >
                  View on OpenSea
                </ActionButton>
              </div>
            </div>
          </div>
        </section>
        <div
          className="App--bookmark"
          id="mint"
          ref={ref => {
            this.mintRef = ref
          }}
        />
        <main className="App--main">
          {/* <HashruneMinter tokenId={this.currentTokenId} /> */}
          <HashruneMinter />
          {/* <header className="App--section-heading">MINT YOUR HASHRUNE</header>
            <div className="App--section-content"></div> */}
        </main>
        <section className="App--section App--section-wide">
          <h1 className="App--heading">Examples</h1>
          <div className="App--gallery">
            {/* <Hashrune
              className="App--gallery-rune"
              edition={0}
              isLink
              name="Blockchain"
              tokenId={2}
              useApi
            />
            <Hashrune
              className="App--gallery-rune"
              edition={0}
              isLink
              name="NFT"
              tokenId={3}
              useApi
            /> */}
            <Hashrune
              className="App--gallery-rune"
              edition={0}
              isLink
              name="Genesis"
              size={360}
              tokenId={4}
              useApi
            />
            <Hashrune
              className="App--gallery-rune"
              edition={0}
              isLink
              name="Non-Fungible Token"
              size={360}
              tokenId={47}
              useApi
            />
            <Hashrune
              className="App--gallery-rune"
              edition={0}
              isLink
              name="Aliens"
              size={360}
              tokenId={349}
              useApi
            />
          </div>
          <div className="App--see-more">
            <ActionButton className="App--action" href="/browse">
              See gallery
            </ActionButton>
          </div>
        </section>
        <div
          ref={ref => {
            this.learnMoreRef = ref
          }}
          className="App--bookmark"
        />
        <section className="App--section">
          <div className="App--tweets">
            <Tweet className="App--tweet" tweetId="1369059945895845889" />
            <Tweet className="App--tweet" tweetId="1369473535425527812" />
          </div>
        </section>
        <section className="App--section">
          <h1 className="App--heading">Details</h1>
          <p>
            <strong>You are a part of the art.</strong> When you mint a
            Hashrune, you are putting art on the blockchain. The appearance of
            the Hashrune depends on the name you gave it during creation.{" "}
          </p>
          <p>
            Each Hashrune is procedurally generated. A rune may have one of the
            following sets of characters:
          </p>
          <ol>
            <li>
              Universal: <code>■▬▮▰▲▶▼◀◆●◖◗◢◣◤◥</code>
            </li>
            <li>
              Blocks: <code>■▬▮▰</code>
            </li>
            <li>
              Arrows: <code>▲▶▼◀</code>
            </li>
            <li>
              Discs: <code>◆●◖◗</code>
            </li>
            <li>
              Corners: <code>◢◣◤◥</code>
            </li>
          </ol>
          <p>
            The name of the Hashrune determines which set is used, the design
            (placement) of the characters, the colors given to each character,
            and the background color. Every web color is possible. The total
            possible number of Hashrunes is a very, very large number. You can
            be sure that no two will be the same.
          </p>
          <p>
            You can view and interact with the contract on{" "}
            <ExternalLink
              href={`https://etherscan.io/address/${Hashrunes.GENESIS_CONTRACT_ADDRESS}#code`}
            >
              Etherscan
            </ExternalLink>
            . Some useful comments about it are written into the contract
            itself:
          </p>
          <pre className="App--contract-info">
            {`\`mint(string name)\`: Mint a Hashrune.
\`getName(uint256 tokenId) -> string\`: Look up the name corresponding to a token id.
\`getTokenId(string name) -> uint256\`: Look up the token id corresponding to a name.
\`getRune(string name) -> string\`: Get the design of a Hashrune.
\`getCharacters(string name) -> string\`: Get the list of characters used for a Hashrune.
\`getColors(string name) -> uint256[]\`: Get a Hashrune's RGB24 colors. Each color corresponds to each character
      in \`getCharacters(name)\`, with an extra color for the background at the end of the list.`}
          </pre>
          <p>
            The contents of any Hashrune, including the design, name, colors,
            and, character theme are all available on the blockchain.
          </p>
          <p>
            For example, try printing the result of calling{" "}
            <code>getRune("Hashrunes")</code>.
          </p>
          <p>
            You'll see that it matches the Hashrune at the top of this page
            (token #1: "Hashrunes").
          </p>
        </section>
      </>
    )
  }
}

const DivContainer = styled.div`
  color: #282c34;
  margin: 0 8px;

  .App--container {
    margin: 0 auto;
    max-width: 1400px;
  }

  .App--external-links {
    display: flex;
    justify-content: flex-end;
    margin-top: 8px;

    .App--external-link {
      align-items: center;
      display: flex;
      margin-left: 12px;

      &:first-child {
        margin-left: 0;
      }

      .App--external-link-badge {
        height: 24px;
        object-fit: contain;
        width: 24px;
      }
    }
  }

  .App--hero-container {
    display: flex;
    flex-direction: column;
    padding: 12px 0;
    width: 100%;

    .App--hero {
      display: flex;
      flex-direction: column;
      max-width: 100%;

      .App--hero-copy {
        .App--hero-brand {
          font-size: 30px;
          font-weight: 500;
          margin: 20px 0;
        }

        .App--hero-title {
          font-size: 20px;
          font-weight: 500;
          margin-bottom: 20px;
        }

        .App--hero-description {
          .App--hero-description-list {
            list-style: disc;
            margin: 8px 0 0 0;
            /* padding-left: 0; */

            .App--hero-description-list-item {
              margin-top: 8px;
            }
          }
        }
      }
    }

    .App--actions {
      padding-top: 16px;

      .App--action:last-child {
        margin-top: 12px;
      }
    }
  }

  .App--section {
    margin: 16px auto 0 auto;
    max-width: 1024px;

    &.App--section-wide {
      max-width: none;

      .App--heading {
        margin-left: auto;
        margin-right: auto;
        max-width: 1024px;
      }
    }
  }

  .App--section-heading {
    font-size: 32px;
    /* font-weight: 600; */
    margin: 40px 0 16px 0;
  }

  /* .App--section-content {
    font-size: 14px;
  } */

  .App--bookmark {
    display: block;
    position: relative;
    top: -78px;
    visibility: hidden;
  }

  .App--gallery {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .App--gallery-rune {
      margin-top: 8px;
      margin-bottom: 8px;
    }
  }

  .App--see-more {
    display: flex;
    justify-content: center;
    margin-top: 16px;
  }

  .App--tweets {
    .App--tweet {
    }
  }

  .App--contract-info {
    overflow: auto;
  }

  @media (min-width: 900px) {
    margin-bottom: 64px;

    .App--external-links {
      display: none;
    }

    .App--hero-container {
      margin: 0 auto;
      max-width: 100%;
      padding: 48px 24px;
      width: 1400px;

      .App--hero {
        align-items: center;
        flex-direction: row;

        .App--hero-rune {
        }

        .App--hero-copy {
          flex: 1 0;
          margin-left: 32px;
          width: 800px;

          .App--hero-brand {
            font-size: 40px;
            margin-top: 0;
          }

          .App--hero-title {
            font-size: 24px;
            margin-top: 0;
            text-align: left;
          }

          .App--hero-description {
            font-size: 18px;
            text-align: left;
          }
        }
      }
    }

    .App--main {
      align-items: center;
      display: flex;
      justify-content: center;
    }

    .App--heading {
      font-size: 32px;
      margin-top: 32px;
    }

    .App--gallery {
      flex-direction: row;

      .App--gallery-rune {
        margin-left: 16px;
        margin-right: 16px;
      }
    }

    .App--tweets {
      display: flex;

      .App--tweet {
        flex: 1 0;
        margin-left: 8px;

        &:first-child {
          margin-left: 0;
        }
      }
    }
  }
`
